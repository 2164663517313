// src/components/CurrencySelector.jsx
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCoin } from "../features/exchange/exchangeSlice";
import { togglePopupVisibility } from "../features/navigation/navigationSlice";
import Coin from "./Coin";
import useRenderCount from "../hooks/useRenderCount";

const CurrencySelector = () => {
  // useRenderCount("CurrencySelector");
  const dispatch = useDispatch();
  const coins = useSelector((state) => state.coins.coins);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const isPopupVisible = useSelector(
    (state) => state.navigation.isPopupVisible
  );
  const handleCoinClick = (coin) => {
    dispatch(setSelectedCoin(coin));
    dispatch(togglePopupVisibility());
  };

  return (
    <>
      <div
        className="currency-selector"
        onClick={() => dispatch(togglePopupVisibility())}
      >
        <div className="currency-info">
          <img
            className="currency-icon"
            src={selectedCoin?.icon}
            alt={selectedCoin?.symbol}
          />
          <span className="currency-name">{selectedCoin?.symbol}</span>
        </div>
        <div
          className={`arrow-icon ${isPopupVisible ? "up" : ""}`}
          alt="arrow down"
        />
      </div>
      {isPopupVisible && (
        <div
          className={`currency-popup transition ${
            isPopupVisible ? "isPopupVisible" : ""
          }`}
        >
          {coins.map((coin) => (
            <Coin
              key={coin.id}
              coin={coin}
              onClick={() => handleCoinClick(coin)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default CurrencySelector;
