// src/components/Page1.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateUSDP,
  setExchangeRate,
  setFee,
} from "../features/exchange/exchangeSlice";
import {
  setIsLoading,
  setRingStatuses,
} from "../features/loading/loadingSlice";
import { moveToPage } from "../features/navigation/navigationSlice";
import {
  setDepositAddress,
  setNetwork,
} from "../features/transaction/transactionSlice";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/fetchUrls";
import { getSearchParams } from "../utils/helpers";
import ExchangeRate from "./ExchangeRate";
import InputGroup from "./InputGroup";

const Page1 = () => {
  // useRenderCount("Page2");
  const dispatch = useDispatch();
  const [currencyName, setCurrencyName] = useState("");
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const amount = useSelector((state) => state.exchange.amount);
  const exchangeRate = useSelector((state) => state.exchange.exchangeRate);
  const fee = useSelector((state) => state.exchange.fee);
  const recipientAddress = useSelector(
    (state) => state.transaction.recipientAddress
  );

  const params = getSearchParams();
  // console.log("Received params:", params);
  // const userWallet = params.wa;
  const userMagicAddress = params.userMagicAddress;

  useEffect(() => {
    if (!selectedCoin) return;

    const getExchangeRate = () => {
      // console.log(`4- ${endpoints.getExchangeRate}/${selectedCoin.symbol}`) // debug

      fetchData(`${endpoints.getExchangeRate}/${selectedCoin?.symbol}`)
        .then((data) => {
          setCurrencyName(selectedCoin?.symbol);
          dispatch(setExchangeRate(data.data));
        })
        .catch((error) => console.error("Error:", error));
    };

    getExchangeRate();
    const timer = setInterval(getExchangeRate, 60000);
    return () => clearInterval(timer);
  }, [dispatch, selectedCoin]);

  useEffect(() => {
    if (selectedCoin) {
      dispatch(setNetwork(selectedCoin?.network));
      setCurrencyName(selectedCoin?.symbol);
      dispatch(setFee(selectedCoin?.feePercentageWithdrawal));
      dispatch(calculateUSDP());
    }
  }, [selectedCoin, amount, exchangeRate, fee, dispatch]);

  const handleContinue = async () => {
    // prevent moving forward if no token where detected on sending wallets
    // if (!await walletBalanceHandler(selectedCoin.name.toLowerCase(), usdpAmount, dispatch)) {return;}

    dispatch(setNetwork(selectedCoin?.network));
    dispatch(setDepositAddress(selectedCoin?.globalWithdrawalAddress));
    dispatch(moveToPage(1));
  };

  return (
    <div className="page1">
      <div className="page1_content">
        <InputGroup />
        <ExchangeRate onClick={handleContinue} />
      </div>
    </div>
  );
};

export default Page1;
