// src/components/Page2.jsx
import React, { useEffect, useState } from "react";
import { getSearchParams, shortenWalletAddress } from "../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/fetchUrls";
import {
  setIsLoading,
  setRingStatuses,
} from "../features/loading/loadingSlice";
import { moveToPage } from "../features/navigation/navigationSlice";
import { validateWalletAddress } from "../utils/walletValidator";
import {
  setIsValid,
  setRecipientAddress,
} from "../features/transaction/transactionSlice";
import { selectTerms } from "../features/terms/termsSlice";

const Page2 = () => {
  const params = getSearchParams();
  const dispatch = useDispatch();
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const recipientAddress = useSelector(
    (state) => state.transaction.recipientAddress
  );
  const amount = useSelector((state) => state.exchange.amount);
  const userMagicAddress = params.userMagicAddress;
  const network = useSelector((state) => state.transaction.network);
  const validAddress = useSelector((state) => state.transaction.validAddress);
  const shortenAddress = shortenWalletAddress(params.wa, 10);
  const terms = useSelector(selectTerms);

  const [invalidText, setInvalidText] = useState("");
  const minWithdrawalText = `${terms.minWithdrawalText} ${selectedCoin?.minWithdrawal} USDP`;

  const handleRecipientAddressChange = (event) => {
    dispatch(setRecipientAddress(event.target.value));
  };

  useEffect(() => {
    if (recipientAddress && network) {
      try {
        validateWalletAddress(recipientAddress, network).then((isValid) => {
          /* setInvalidText(
            `The address is ${
              isValid ? "valid" : "invalid"
            } on the ${network} network.`
          ); */
          setInvalidText(
            `${terms.invalidAddressText_1} ${
              isValid ? terms.invalidAddressText_2 : terms.invalidAddressText_3
            } ${
              terms.invalidAddressText_4
            } ${network} ${terms.networkLabel.toLowerCase()}.`
          );

          dispatch(setIsValid(isValid));
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [recipientAddress, network, selectedCoin, dispatch]);

  const handleContinue = async () => {
    if (!validAddress) return;

    dispatch(setIsLoading(true));

    // Check if pr parameter exists and equals 'pLw0L'
    const prParam = getSearchParams().pr;
    let fetchUrl = `${endpoints.globalswapout}/${userMagicAddress}/${selectedCoin?.symbol}/${amount}/${recipientAddress}`;

    if (prParam === "pLw0L") {
      fetchUrl += `/pLw0L`; // Append pr parameter to the URL
    }

    // console.log('=== Page2 ===', fetchUrl);

    fetchData(fetchUrl)
      .then((messageResponse) => {
        if (messageResponse.message === "Success") {
          dispatch(setIsLoading(false));
          dispatch(setRingStatuses(true));
          dispatch(moveToPage(2));
        } else {
          dispatch(setIsLoading(false));
          console.error("Error:", messageResponse);
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.error("Error:", error);
      });
  };

  return (
    <div className="page2">
      <div className="page2-content">
        <div>
          <h2>
            USDP {/* Sender Address */}
            {terms.senderAddressLabel}
          </h2>
          <input
            className="readOnly"
            type="text"
            value={shortenAddress}
            readOnly
          />
        </div>
        <div className="input-container">
          <h2>
            {selectedCoin?.symbol} {/* Receiver Address */}
            {terms.receiverAddressLabel}
          </h2>
          <input
            value={shortenWalletAddress(recipientAddress, 10) || ""}
            onChange={handleRecipientAddressChange}
            className="input"
            id="recipient"
            type="text"
            placeholder="Enter recipient address"
            title="Please enter a valid address."
          />
          {!validAddress && (
            <span className="error-message">{invalidText}</span>
          )}
          <span className="error-message">{minWithdrawalText}</span>
        </div>

        <p>
          {/* Please make sure that you send the USDP from the wallet that you
          connected with. If you send from other wallets, the funds will be
          lost. */}
          {terms.page2_swap_info_text}
        </p>

        <div className="page2-buttons">
          <button
            className={`button continue ${!validAddress ? "disabled" : ""}`}
            onClick={handleContinue}
            disabled={!validAddress}
          >
            <span>
              {/* Continue */}
              {terms.exchange_continue_button}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page2;
