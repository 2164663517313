// src/utils/useWebSocket.js
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setWsData } from "../features/transaction/transactionSlice";

const useWebSocket = (url) => {
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  const reconnectInterval = useRef(null);

  const connect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
    }
    const newSocket = new WebSocket(url);
    socketRef.current = newSocket;

    newSocket.addEventListener("open", () => {
      console.log("Connected to WebSocket at:", url);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    });

    newSocket.addEventListener("message", (event) => {
      dispatch(setWsData(event.data));
    });

    newSocket.addEventListener("close", () => {
      console.log("WebSocket closed, attempting to reconnect");
      if (!reconnectInterval.current) {
        reconnectInterval.current = setInterval(connect, 5000); // Try to reconnect every 5 seconds
      }
    });

    newSocket.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
      newSocket.close();
    });
  }, [url, dispatch]);

  useEffect(() => {
    connect();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  }, [connect]);

  const disconnect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
    if (reconnectInterval.current) {
      clearInterval(reconnectInterval.current);
      reconnectInterval.current = null;
    }
  }, []);

  return { socket: socketRef.current, disconnect, connect };
};

export default useWebSocket;
