// src/components/Page4.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWsData } from "../features/transaction/transactionSlice"; // Add this import to set wsData
import { getSearchParams } from "../utils/helpers";
import { selectTerms } from "../features/terms/termsSlice";

const Page4 = () => {
  const dispatch = useDispatch();
  const wsData = useSelector((state) => state.transaction.wsData);
  const transactionStatuses = useSelector(
    (state) => state.transactionStatus.statuses
  );
  const [ringStatuses, setRingStatuses] = useState([false, false, false]);

  const params = getSearchParams();
  const userMagicAddress = params.userMagicAddress?.toLowerCase();

  // Fake WebSocket data for testing with a timer
  /*   useEffect(() => {
    const timer1 = setTimeout(() => {
      const fakeWsData1 = JSON.stringify({
        streamerMessage: {
          walletAddress: userMagicAddress,
          timestamp: new Date().toISOString(),
          status: 'usdp_received',
          amount: '100.00 USDT'
        }
      });

      dispatch(setWsData(fakeWsData1));

      const timer2 = setTimeout(() => {
        const fakeWsData2 = JSON.stringify({
          streamerMessage: {
            walletAddress: userMagicAddress,
            timestamp: new Date().toISOString(),
            status: 'funds_received_by_user',
            amount: '100.00 USDT'
          }
        });

        dispatch(setWsData(fakeWsData2));
      }, 6000); // Additional 6 seconds

      return () => clearTimeout(timer2);
    }, 6000); // 6 seconds

    return () => {
      clearTimeout(timer1);
      dispatch(setWsData(null));
    };
  }, [dispatch, userMagicAddress]); */
  // End of fake WebSocket data

  useEffect(() => {
    if (wsData) {
      const messageData = JSON.parse(wsData);
      const { streamerMessage } = messageData;
      console.log("Streamer message:", streamerMessage);

      if (
        !userMagicAddress ||
        userMagicAddress.toLowerCase() !==
          streamerMessage.walletAddress.toLowerCase()
      ) {
        console.log("User magic address does not match");
        return;
      }

      if (streamerMessage) {
        const newStatus = streamerMessage.status.toLowerCase();
        setRingStatuses((prevStatuses) => {
          let updatedStatuses;
          switch (newStatus) {
            case "usdp_received":
              updatedStatuses = [true, false, false];
              break;
            case "funds_received_by_user":
              updatedStatuses = [true, true, true];
              break;
            default:
              updatedStatuses = prevStatuses;
          }
          return updatedStatuses;
        });
      }
    }
  }, [wsData, transactionStatuses, userMagicAddress]);

  return (
    <div className="page4">
      <TransactionStatus ringStatuses={ringStatuses} />
    </div>
  );
};

const TransactionStatus = ({ ringStatuses }) => {
  const terms = useSelector(selectTerms);
  // const texts = ["Transaction arrived", "Exchanging", "Transaction complete"];
  const texts = [
    terms.page4_transaction_arrived,
    terms.page4_exchanging,
    terms.page4_transaction_complete,
  ];
  return (
    <div className="container">
      {ringStatuses.map((ringStatus, i) => (
        <div key={i} className="item">
          <div className="top">
            <div className={`ring ${ringStatus ? "full" : ""}`}>
              <svg height="200" width="200">
                <circle
                  className={`circle ${ringStatus ? "" : "loading"}`}
                  cx="100"
                  cy="100"
                  r="40"
                  stroke="#fe9725"
                  strokeWidth="6"
                  fillOpacity="0"
                />
              </svg>
              <div className="image" />
            </div>
          </div>
          <div className="bottom">
            <div className="text">{texts[i]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Page4;
