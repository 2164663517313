// src/features/transaction/transactionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    depositAddress: null,
    network: null,
    wsData: null,
    status: 1,
    recipientAddress: null,
    validAddress: false,
  },
  reducers: {
    setDepositAddress: (state, action) => {
      state.depositAddress = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setWsData: (state, action) => {
      state.wsData = action.payload;
      console.log("wsData", state.wsData);
    },
    updateStatus: (state, action) => {
      state.status = Math.min(Math.max(action.payload, 1), 3);
    },
    setRecipientAddress: (state, action) => {
      state.recipientAddress = action.payload;
    },
    setIsValid: (state, action) => {
      state.validAddress = action.payload;
    },
  },
});

export const {
  setDepositAddress,
  setNetwork,
  setWsData,
  updateStatus,
  setRecipientAddress,
  setIsValid,
} = transactionSlice.actions;
export default transactionSlice.reducer;
