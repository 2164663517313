// src/utils/helpers.js
export const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const shortenWalletAddress = (address, length = 14) => {
  if (!address || address.length < length * 2) return address;
  return `${address.slice(0, length)}...${address.slice(-length)}`;
};

