// src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
import coinsReducer from "../features/coins/coinsSlice";
import exchangeReducer from "../features/exchange/exchangeSlice";
import hostReducer from "../features/host/hostSlice";
import loadingReducer from "../features/loading/loadingSlice";
import navigationReducer from "../features/navigation/navigationSlice";
import paymentReducer from "../features/payment/paymentSlice";
import termsReducer from "../features/terms/termsSlice.js";
import themeReducer from "../features/theme/themeSlice";
import transactionReducer from "../features/transaction/transactionSlice";
import transactionStatusReducer from "../features/transactionStatus/transactionStatusSlice";

const store = configureStore({
  reducer: {
    coins: coinsReducer,
    exchange: exchangeReducer,
    loading: loadingReducer,
    navigation: navigationReducer,
    payment: paymentReducer,
    theme: themeReducer,
    transaction: transactionReducer,
    transactionStatus: transactionStatusReducer,
    host: hostReducer,
    terms: termsReducer,
  },
});

export default store;
