// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  // useRenderCount("Footer");
  return (
  <div className="footer">
    <div className="footer_logo" />
  </div>
);
};

export default Footer;