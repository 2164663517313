// src/utils/useFetchCoins.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCoins } from "../features/coins/coinsSlice";

export const useFetchCoins = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCoins());
  }, [dispatch]);
};
