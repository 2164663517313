import store from "../store/index";
import { setTheme } from "../features/theme/themeSlice";

export function postMessageListener(updateLanguageCallback) {
  window.addEventListener("message", (event) => {
    const state = store.getState();
    if (!state.host.isAllowedDomain) {
      return;
    }

    const type = event.data?.type;

    switch (type) {
      case "LANGUAGE":
        const language = event.data?.language;
        console.log("Received language change event:", language);
        if (typeof language === "string" && language.length === 2) {
          updateLanguageCallback(language);
        }
        break;
      case "THEME":
        const theme = event.data?.theme;
        console.log("Received theme change event:", theme);
        if (
          typeof theme === "string" &&
          (theme === "dark" || theme === "light")
        ) {
          store.dispatch(setTheme(theme));
        }
        break;
      default:
        // console.warn("Unknown message type:", type);
        break;
    }
  });
}
