// src/components/PageBox.jsx
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetExchange, setSelectedCoin } from '../features/exchange/exchangeSlice';
import { decrementStatus, handleBackClick, moveToPage } from '../features/navigation/navigationSlice';
import { updateStatus } from '../features/transaction/transactionSlice';
import Footer from './Footer';
import Header from './Header';
import InnerPagesComponents from './InnerPagesComponents';
import SingleTransactionStatus from './SingleTransactionStatus';

const PageBox = () => {
  // useRenderCount("PageBox");
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const componentKey = useSelector((state) => state.navigation.componentKey);
  const coins = useSelector((state) => state.coins.coins);
  const [isLoading, setIsLoading] = useState(true);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const ringStatuses = useSelector((state) => state.loading.ringStatuses);

  const onBackClick = useCallback(() => {
    // Reset exchange values when navigating back to Page2 from Page3 or Page1 from Page2
    if (componentKey === 1 || componentKey === 2) {
      dispatch(resetExchange());
    }

    if (componentKey >= 1) {
      dispatch(decrementStatus());
      dispatch(updateStatus(componentKey));
      dispatch(moveToPage(componentKey - 1));
    } else {
      dispatch(handleBackClick());
    }
  }, [componentKey, dispatch]);

  const headerProps = useMemo(() => ({
    index: componentKey,
    onBackClick,
    decrementStatus: () => dispatch(decrementStatus()),
  }), [componentKey, onBackClick, dispatch]);

  useEffect(() => {
    if (coins.length > 0) {
      setIsLoading(false);
      if(!selectedCoin && !selectedCoin?.name) {
        const usdtCoin = coins.find(coin => coin.name.includes('USDT TRC20'));
        dispatch(setSelectedCoin(usdtCoin || coins[0]));
      }
    }
  }
  , [dispatch, coins]);

  if (isLoading) {
    return(
      <div className={`page-box ${theme}`}>
        <Header {...headerProps} />
          {isLoading && <SingleTransactionStatus ringStatuses={ringStatuses} />}
        <Footer />
      </div>
    );
  }

  return (
    <div className={`page-box ${theme}`}>
      <Header {...headerProps} />
      <div className="content">
        <InnerPagesComponents />
      </div>
      <Footer />
    </div>
  );
};

export default PageBox;
