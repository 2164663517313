// src/components/SwapTimer.jsx
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTerms } from "../features/terms/termsSlice";

const SwapTimer = () => {
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const terms = useSelector(selectTerms);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  return (
    <div className="swap-timer">
      <div className="swap-timer-text">
        {/* Swap can take a few minutes. */}
        {terms.swaptimer_swap_time_info}
      </div>
      {/* <div className="swap-timer-time">
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div> */}
    </div>
  );
};

export default SwapTimer;
