// src/components/InnerPagesComponents.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';

const InnerPagesComponents = () => {
  // useRenderCount("InnerPagesComponents");
  const index = useSelector((state) => state.navigation.componentKey);
  const components = [<Page1 key={0}/>, <Page2 key={1} />, <Page3 key={2} />, <Page4 key={3} />];

  return components[index];
};

export default InnerPagesComponents;
