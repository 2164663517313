// src/utils/fetchUrls.js
const baseURL = "https://rb.coinsexchange.com";

export const endpoints = {
  getExchangeRate: `${baseURL}/getexchangerate`,
  getCoinsList: `${baseURL}/getcoinslist`,
  getWalletForUser: `${baseURL}/getWalletForUser`,
  getMessage: `${baseURL}/swapout`,
  globalswapout: `${baseURL}/globalswapout`,
};


// https://rb.coinsexchange.com/globalswapout/magicWalletAddress/coin/amount/walletAddress
// For example:
// https://rb.coinsexchange.com/globalswapout/0x74e04B01A108f35E352432dC8016f5a6632D922f/USDT-TRC20/11/TD4oosgQTX3ewGMrdUTMpMxGt9AahnsVPN
// https://rb.coinsexchange.com/globalswapout/0xBca8318ebcd7Be7B8229d4D548F2F4E75CB4Ca27/USDT-TRC20/20/TD4oosgQTX3ewGMrdUTMpMxGt9AahnsVPN/pLw0L