// src/features/payment/paymentSlice.js
import { createSlice } from "@reduxjs/toolkit";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    copied: false,
    isPaymentCompleted: false,
  },
  reducers: {
    setCopied: (state, action) => {
      state.copied = action.payload;
    },
    setIsPaymentCompleted: (state, action) => {
      state.isPaymentCompleted = action.payload;
    },
  },
});

export const { setCopied, setIsPaymentCompleted } = paymentSlice.actions;
export default paymentSlice.reducer;
