// src/features/navigation/navigationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    componentKey: 0,
    isPopupVisible: false,
  },
  reducers: {
    moveToPage: (state, action) => {
      state.componentKey = action.payload;
    },
    handleBackClick: (state) => {
      if (state.componentKey > 0) {
        state.componentKey -= 1;
      }
    },
    togglePopupVisibility: (state) => {
      state.isPopupVisible = !state.isPopupVisible;
    },
    decrementStatus: (state) => {
      if (state.componentKey > 1) {
        state.componentKey -= 1;
      }
    },
  },
});

export const {
  moveToPage,
  handleBackClick,
  togglePopupVisibility,
  decrementStatus,
} = navigationSlice.actions;
export default navigationSlice.reducer;
