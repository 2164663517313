// src/utils/initialCoinsData.js
// Import all the images from the images folder
// Import all the images from the images folder
// Import all the images from the images folder
import ADA_icon from "../Images/ADA.png";
import ARB_icon from "../Images/ARB.png"; // eslint-disable-line no-unused-vars
import BNB_icon from "../Images/BNB.png"; // eslint-disable-line no-unused-vars
import BTC_icon from "../Images/BTC.png";
import DOGE_icon from "../Images/DOGE.png";
import ETH_icon from "../Images/ETH.png";
import LTC_icon from "../Images/LTC.png";
import MATIC_icon from "../Images/MATIC.png";
import SHIB_icon from "../Images/SHIB.png";
import SOL_icon from "../Images/SOL.png";
import TRX_icon from "../Images/TRX.png"; // eslint-disable-line no-unused-vars
import USDC_icon from "../Images/USDC.png";
import {
  default as USDT_ERC20_icon,
  default as USDT_TRC20_icon,
} from "../Images/USDT.png";
import XRP_icon from "../Images/XRP.png";
import UNI from "../Images/UNI.png";
import AVAX from "../Images/AVAX.png";
import BCH from "../Images/BCH.png";

export const initialCoinsData = [
  { name: "BTC", icon: BTC_icon }, // id 1
  { name: "ETH", icon: ETH_icon }, // id 2
  { name: "POL", icon: MATIC_icon }, // id 3
  { name: "USDT-ERC20", icon: USDT_ERC20_icon }, // id 4
  { name: "USDC", icon: USDC_icon }, // id 5
  { name: "USDT-TRC20", icon: USDT_TRC20_icon }, // id 6
  { name: "UNI", icon: UNI }, // id 7
  // { name: "BNB", icon: BNB_icon }, // id 7
  { name: "SOL", icon: SOL_icon }, // id 8
  { name: "ADA", icon: ADA_icon }, // id 9
  { name: "SHIB", icon: SHIB_icon }, // id 10
  { name: "DOGE", icon: DOGE_icon }, // id 11
  { name: "LTC", icon: LTC_icon }, // id 12
  { name: "AVAX", icon: AVAX }, // id 13
  // { name: "ARB", icon: ARB_icon }, // id 13
  { name: "XRP", icon: XRP_icon }, // id 14
  { name: "BCH", icon: BCH }, // id 15
  // { name: "TRX", icon: TRX_icon }, // id 15
];
