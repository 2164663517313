// src/features/transactionStatus/transactionStatusSlice.js
import { createSlice } from "@reduxjs/toolkit";

const transactionStatusSlice = createSlice({
  name: "transactionStatus",
  initialState: {
    statuses: {
      funds_received: [true, false, false],
      funds_sent_to_user: [true, true, false],
      funds_received_by_user: [true, true, true],
    },
  },
  reducers: {},
});

export default transactionStatusSlice.reducer;
