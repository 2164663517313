// src/components/InputGroup.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usdpIcon from "../Images/USDP.png";
import { setAmount } from "../features/exchange/exchangeSlice";
import {
  setIsValid,
  setRecipientAddress,
} from "../features/transaction/transactionSlice";
import { validateWalletAddress } from "../utils/walletValidator";
import CurrencySelector from "./CurrencySelector";
import { selectTerms } from "../features/terms/termsSlice";

const InputGroup = () => {
  const currency = "USDP";
  const dispatch = useDispatch();
  const { usdpAmount, selectedCoin } = useSelector((state) => state.exchange);
  const { recipientAddress, network } = useSelector(
    (state) => state.transaction
  );
  const validAddress = useSelector((state) => state.transaction.validAddress);
  const terms = useSelector(selectTerms);

  const [invalidText, setInvalidText] = useState("");
  const minWithdrawalText = `${terms.minWithdrawalText} ${selectedCoin?.minWithdrawal} USDP`;

  const handleAmountChange = (event) => {
    dispatch(setAmount(event.target.value));
  };

  const handleRecipientAddressChange = (event) => {
    dispatch(setRecipientAddress(event.target.value));
  };

  useEffect(() => {
    if (recipientAddress && network) {
      try {
        validateWalletAddress(recipientAddress, network).then((isValid) => {
          setInvalidText(
            `The address is ${
              isValid ? "valid" : "invalid"
            } on the ${network} network.`
          );
          dispatch(setIsValid(isValid));
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [recipientAddress, network, selectedCoin, dispatch]);

  return (
    <div className="input-group">
      <div className="input-container">
        <label htmlFor="input1" className="input-label">
          {/* You send */}
          {terms.input_group_you_send_label}
        </label>
        <div className="currency_input_wrap">
          <input
            className="input"
            id="input1"
            type="number"
            inputMode="decimal"
            pattern="[0-9]*"
            min="0"
            placeholder={terms.input_group_enter_amount_placeholder}
            onChange={handleAmountChange}
          />
          <div className="usdp-container">
            <img
              className="usdp-icon"
              src={usdpIcon}
              alt={`${currency} icon`}
            />
            <span className="currency-name">{currency}</span>
          </div>
        </div>
      </div>
      {/*  */}
      <br />
      <div className="input-container">
        <label htmlFor="input2" className="input-label">
          {/* You receive (approximately) ≈ */}
          {terms.input_group_you_receive_label}
        </label>
        <div className="currency_input_wrap">
          <input
            className="input"
            id="input2"
            type="number"
            min="0"
            value={usdpAmount}
            readOnly
          />
          <CurrencySelector />
        </div>
      </div>

      <div className="input-container">
        {/*  */}
        <br />
        {/* <div className="currency_input_wrap">
          <input value={recipientAddress} onChange={handleRecipientAddressChange} className="input" id="recipient" type="text" placeholder="Enter recipient address" title="Please enter a valid address." />
        </div> */}
        {/* {!validAddress && <span className="error-message">{invalidText}</span>} */}
        <span className="error-message">{minWithdrawalText}</span>
        {/*  */}
        <br />
      </div>
    </div>
  );
};

export default InputGroup;
