// src/App.js
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import PageBox from "./components/PageBox";
import SingleTransactionStatus from "./components/SingleTransactionStatus";
import { selectTermsLoaded } from "./features/terms/termsSlice";
import { getSearchParams } from "./utils/helpers.js";
import { getLanguageTerms, processLanguageTerms } from "./utils/languageUtils";
import { postMessageListener } from "./utils/postMessageListener";
import { TermsUtil } from "./utils/terms";
import { useFetchCoins } from "./utils/useFetchCoins";
import useWebSocket from "./utils/useWebSocket";
import { wsEndpoints } from "./utils/wsUrls";
import { resetHostDomain, setHostDomain } from "./features/host/hostSlice.js";

function App() {
  // useRenderCount("App");
  const { disconnect, connect } = useWebSocket(wsEndpoints.production);
  const dispatch = useDispatch();
  useFetchCoins();
  const loaded = useRef(null);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const ringStatuses = useSelector((state) => state.loading.ringStatuses);
  const termsLoaded = useSelector(selectTermsLoaded);

  console.log("=== Playnance Reverse Bridge Front ===");

  useEffect(() => {
    if (loaded.current) return;
    loaded.current = true;

    const updateLanguageCallback = async (language) => {
      // Handle language update
      // console.log("Language updated to:", language);
      const rawData = await getLanguageTerms(language);
      const newTerms = await processLanguageTerms(rawData, language);
      if (newTerms) {
        TermsUtil.setTerms(newTerms);
        // console.log("Terms updated for language:", language);
      } else {
        console.warn("Failed to fetch new terms for language:", language);
        // then make a default language call with 'en'
        const defaultData = await getLanguageTerms("en");
        const defaultTerms = await processLanguageTerms(defaultData, "en");
        if (defaultTerms) {
          TermsUtil.setTerms(defaultTerms);
          // console.log("Default terms updated for language: en");
        } else {
          console.warn("Failed to fetch default terms for language: en");
        }
      }
    };

    try {
      // Initialize the language listener with the callback
      if (window.self !== window.top) {
        postMessageListener(updateLanguageCallback);
      } else {
        updateLanguageCallback("en");
      }
    } catch (error) {
      console.error("Error initializing postMessageListener:", error);
    }

    const params = getSearchParams();
    const allowedOrigins = ["localhost:3002", "coinsexchange.com"];
    console.log("Received appDomain:", params.appDomain);

    if (params.appDomain && allowedOrigins.includes(params.appDomain)) {
      console.log("Params from allowed origin:", params.appDomain);
      dispatch(setHostDomain(params.appDomain));
    } else {
      console.log("Params from unknown origin:", params.appDomain);
      dispatch(resetHostDomain());
    }

    return () => {
      dispatch(resetHostDomain());
      disconnect();
    };
    // eslint-disable-next-line
  }, []);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      connect();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return (
    <div className="App">
      {isLoading && <SingleTransactionStatus ringStatuses={ringStatuses} />}
      <PageBox />
    </div>
  );
}

export default App;
