// src/utils/languageUtils.js
import axios from "axios";

const BASE_URL = "https://api.playblock.io/v1/config/terms/coinsexchange";

export const getLanguageTerms = async (lang = "en") => {
  try {
    const response = await axios.get(`${BASE_URL}?lang=${lang}`);
    // console.log(`Fetched language terms for '${lang}':`, response.data); // for debugging
    return response.data;
  } catch (error) {
    console.error(`Error fetching language terms for '${lang}':`, error);
    return null;
  }
};

export const processLanguageTerms = async (data, currentLangCode) => {
  const termsObject = data[currentLangCode];
  const termsArray = Object.entries(termsObject).map(
    ([termKey, termValue]) => ({
      termKey,
      termValue,
    })
  );

  if (termsArray && termsArray.length > 0) {
    return termsArray;
  } else {
    const defaultData = await getLanguageTerms("en");
    const defaultTermsObject = defaultData["en"];
    const defaultTermsArray = Object.entries(defaultTermsObject).map(
      ([termKey, termValue]) => ({
        termKey,
        termValue,
      })
    );
    return defaultTermsArray;
  }
};
