// src/components/Header.jsx
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../features/theme/themeSlice";
import ThemeToggle from "./ThemeToggle";
import { selectTerms } from "../features/terms/termsSlice";

const Header = ({ index, onBackClick }) => {
  // useRenderCount("Header");
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const terms = useSelector(selectTerms);

  const titles = {
    0: terms.header_title_sell_usdp,
    1: terms.header_title_sell_usdp,
    2: terms.header_title_sell_usdp,
    3: terms.header_title_sell_usdp,
  };

  const title = titles[index] || titles[0];
  const preventPrevious = [0, 3];

  return (
    <div className={`header ${theme}`}>
      {preventPrevious.includes(index) ? (
        <div />
      ) : (
        <div className="back" onClick={onBackClick} />
      )}
      <ThemeToggle theme={theme} toggleTheme={() => dispatch(toggleTheme())} />
      <div className="header_title">{title}</div>
      <div className="logo" />
    </div>
  );
};

export default Header;
