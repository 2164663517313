// src/components/Page3.jsx
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { moveToPage } from "../features/navigation/navigationSlice";
import {
  setCopied,
  setIsPaymentCompleted,
} from "../features/payment/paymentSlice";
import { setWsData } from "../features/transaction/transactionSlice";
import { getSearchParams, shortenWalletAddress } from "../utils/helpers";
import QRContainer from "./QRContainer";
import SwapTimer from "./SwapTimer";
import { copyToClipboard } from "../utils/copyToClipboard";
import { selectTerms } from "../features/terms/termsSlice";

const Page3 = () => {
  const { wsData } = useSelector((state) => state.transaction);
  const isPaymentCompleted = useSelector(
    (state) => state.payment.isPaymentCompleted
  );
  const dispatch = useDispatch();
  const params = getSearchParams();
  const userMagicAddress = params.userMagicAddress?.toLowerCase();
  const recipientAddress = useSelector(
    (state) => state.transaction.recipientAddress
  );
  const copied = useSelector((state) => state.payment.copied);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const depositAddress = useSelector(
    (state) => state.transaction.depositAddress
  );
  const terms = useSelector(selectTerms);

  dispatch(setIsPaymentCompleted(false));

  // Fake WebSocket data for testing with a timer
  /*   useEffect(() => {
    const timer = setTimeout(() => {
      const fakeWsData = JSON.stringify({
        streamerMessage: {
          walletAddress: userMagicAddress,
          timestamp: new Date().toISOString(),
          status: 'usdp_received'
        }
      });

      dispatch(setWsData(fakeWsData));
    }, 6000); // 6 seconds

    return () => {
      clearTimeout(timer);
      dispatch(setWsData(null));
    }
  }, [dispatch, userMagicAddress]); */
  // End of fake WebSocket data

  useEffect(() => {
    if (!wsData) return;

    let parsedData;
    try {
      parsedData = JSON.parse(wsData);
    } catch (error) {
      return;
    }

    const streamerMessage = parsedData?.streamerMessage;
    if (!streamerMessage) return;
    console.log("streamerMessage:", streamerMessage);
    const { walletAddress, timestamp, status: wsStatus } = streamerMessage;
    if (!walletAddress || !timestamp || !wsStatus) return;

    const messageTimestampInUTC = new Date(timestamp).getTime();
    const isWithinTimeLimit = Date.now() <= messageTimestampInUTC + 30 * 1000;
    // debugg start
    if (userMagicAddress.toLowerCase() === walletAddress.toLowerCase()) {
      console.log(
        "userMagicAddress.toLowerCase() === walletAddress.toLowerCase()\n",
        userMagicAddress.toLowerCase() === walletAddress.toLowerCase()
      );
    }
    if (isWithinTimeLimit) {
      console.log("isWithinTimeLimit\n", isWithinTimeLimit);
    }
    if (
      ["usdp_received", "funds_received_by_user"].includes(
        wsStatus.toLowerCase()
      )
    ) {
      console.log(
        "usdp_received",
        "funds_received_by_user\n",
        wsStatus.toLowerCase()
      );
    }
    // debugg end
    if (
      userMagicAddress === walletAddress.toLowerCase() &&
      isWithinTimeLimit &&
      ["usdp_received", "funds_received_by_user"].includes(
        wsStatus.toLowerCase()
      )
    ) {
      dispatch(setIsPaymentCompleted(true));
      dispatch(moveToPage(3));
    }
  }, [wsData, userMagicAddress, dispatch]);

  const handleCopy = () => {
    copyToClipboard(depositAddress).then((success) => {
      if (success) {
        dispatch(setCopied(true));
        setTimeout(() => {
          dispatch(setCopied(false));
        }, 1500);
      }
    });
  };

  return (
    <div className="page3">
      <div className="page3_content">
        <div className="send-address-container">
          <label htmlFor="send-address" className="send-address-label">
            {/* Send USDP to this Address */}
            {terms.page3_send_currency_label_1} USDP{" "}
            {terms.page3_send_currency_label_2}
          </label>
          <input
            className="send-address-input"
            id="send-address"
            type="text"
            value={shortenWalletAddress(depositAddress)}
            readOnly
          />
          <div className="tooltip">{depositAddress}</div>
          <div
            className={`icon ${copied ? "copied" : ""}`}
            onClick={handleCopy}
            data-copied={copied.toString()}
            data-copy-text={terms.page3_global_copy} // e.g., "Copy address"
            data-copied-text={terms.page3_global_copy_success} // e.g., "Copied"
          />
          <SwapTimer />
        </div>
        <QRContainer />
        <div className="recipient-container">
          <label htmlFor="recipient" className="recipient-label">
            {/* Recpient address */}
            {terms.page3_recipient_address_label}
          </label>
          <div className="recipient-address">{recipientAddress}</div>
        </div>
        <button
          className={`button orange ${!isPaymentCompleted ? "disabled" : ""}`}
          disabled={!isPaymentCompleted}
        >
          <span className="button-text">
            {/* Waiting for deposit */}
            {terms.page3_waiting_for_deposit}
          </span>
          {!isPaymentCompleted && <div className="loader" />}
        </button>
      </div>
    </div>
  );
};

export default Page3;
