// src/components/ThemeToggle.jsx

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moonIcon from "../Images/toggle_moon.png";
import sunIcon from "../Images/toggle_sun.png";
import { toggleTheme } from "../features/theme/themeSlice";

const ThemeToggle = () => {
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  return (
    <div className="theme-toggle">
      <input
        type="checkbox"
        id="toggle"
        className="toggle-checkbox"
        checked={theme === "light"} // Added checked attribute
        onChange={() => dispatch(toggleTheme())}
      />
      <label htmlFor="toggle" className="toggle-label">
        <span className="toggle-button" />
        <img
          src={theme === "dark" ? sunIcon : moonIcon}
          alt={theme === "dark" ? "Sun" : "Moon"}
          className="theme-icon"
        />
      </label>
    </div>
  );
};

export default ThemeToggle;
