// src/components/ExchangeRate.jsx
import React from "react";
import { useSelector } from "react-redux";
import { selectTerms } from "../features/terms/termsSlice";

const ExchangeRate = ({ onClick }) => {
  const amount = useSelector((state) => state.exchange.amount);
  const exchangeRate = useSelector((state) => state.exchange.exchangeRate);
  const fee = useSelector((state) => state.exchange.fee);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const validAddress = useSelector((state) => state.transaction.validAddress);
  const network = useSelector((state) => state.transaction.network);
  const terms = useSelector(selectTerms);

  const isButtonDisabled =
    !amount ||
    amount < selectedCoin?.minWithdrawal ||
    !exchangeRate ||
    fee < 0 ||
    !selectedCoin; /* || !validAddress */

  return (
    <div className="exchange-rate">
      <div className="exchange-txt-row">
        <span className="exchange-rate-label">
          {/* Exchange rate: 1 */}
          {terms.exchange_rate_label} USDP ≈ {exchangeRate}{" "}
          {selectedCoin?.symbol}
        </span>
        <span className="fee">
          {/* Fee: */}
          {terms.exchange_fee_label}: {fee}% + {terms.networkFeesText}
        </span>
      </div>

      <div className="funds-transferred">
        <div className="funds-txt-row">
          <div className="network-text">
            {/* Network */}
            {terms.networkLabel}: {network} <div className="icon" />
          </div>
          <div className="divider" />
          <div className="funds-text">
            <div className="funds-icon" />
            {/* Funds transferred immediately */}
            {terms.funds_transferred_text}
          </div>
        </div>

        <button
          className={`button orange ${isButtonDisabled ? "disabled" : ""}`}
          disabled={isButtonDisabled}
          onClick={onClick}
        >
          <span>
            {/* Continue */}
            {terms.exchange_continue_button}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ExchangeRate;
