// src/features/host/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";

const hostSlice = createSlice({
  name: "host",
  initialState: {
    domain: null,
    isAllowedDomain: false,
    userMagicAddress: null,
  },
  reducers: {
    setHostDomain: (state, action) => {
      state.domain = action.payload;
      state.isAllowedDomain = ["localhost:3002", "coinsexchange.com"].includes(
        action.payload
      );
    },
    resetHostDomain: (state) => {
      state.domain = null;
      state.isAllowedDomain = false;
    },
    checkAllowedDomain: (state) => {
      state.isAllowedDomain = ["localhost:3002", "coinsexchange.com"].includes(
        state.domain
      );
    },
    setUserMagicAddress: (state, action) => {
      state.userMagicAddress = action.payload;
    },
  },
});

export const {
  setHostDomain,
  resetHostDomain,
  checkAllowedDomain,
  setUserMagicAddress,
} = hostSlice.actions;
export default hostSlice.reducer;
