// src/components/QRContainer.jsx
import QRCode from "qrcode-svg";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectTerms } from "../features/terms/termsSlice";

const QRContainer = () => {
  const depositAddress = useSelector(
    (state) => state.transaction.depositAddress
  );
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const qr = new QRCode({
    content: depositAddress,
    width: 300,
    height: 300,
    color: "#000000",
    background: "#d4d4d4",
    ecl: "L",
    padding: 2,
    join: true,
    pretty: true,
    xmlDeclaration: true,
    container: "svg-viewbox",
  });
  const svgText = qr.svg();

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
    if (!isOverlayVisible) {
      window.parent.postMessage(
        { type: "showCloseButton", svgContent: svgText },
        "*"
      );
      console.log("Show close button message sent to parent");
    } else {
      window.parent.postMessage({ type: "hideCloseButton" }, "*");
      console.log("Hide close button message sent to parent");
    }
  };

  return (
    <div className="qr-container">
      <div
        onClick={toggleOverlay}
        dangerouslySetInnerHTML={{ __html: svgText }}
      />
      <div className="info-container">
        <InfoRow label="Network" />
      </div>

      {isOverlayVisible && (
        <div className="qr-overlay fade-in" onClick={toggleOverlay}>
          <div className="qr-overlay-content">
            <div dangerouslySetInnerHTML={{ __html: svgText }} />
          </div>
        </div>
      )}
    </div>
  );
};

const InfoRow = ({ label }) => {
  const terms = useSelector(selectTerms);
  const usdpDetails = {
    coinName: "USDP",
    network: "Playblock",
  };

  return (
    <div className={`info-row-${label.toLowerCase()}`}>
      <div className="info-text">
        {label === "Network" && (
          <div className="info-label">
            {/* Send */}
            {terms.qrcontainer_send_only_1}{" "}
            <strong>
              {/* Only */}
              {terms.qrcontainer_send_only_2}
            </strong>
            :
          </div>
        )}
        <div className="info-value">{`${usdpDetails.network} ${label} ${usdpDetails.coinName}`}</div>
      </div>
      <div className="icon network" />
    </div>
  );
};

export default React.memo(QRContainer);
